// import Swiper JS
import Swiper, {Navigation, Grid} from 'swiper';
// import Swiper styles


var newsSwiper = new Swiper(".news-swiper", {
    slidesPerView: 1,
    slidesPerColumn: 2,
    modules: [Navigation, Grid],
    grid: {
        rows: 2
    },
    spaceBetween: 30,
    navigation: {
        nextEl: ".news-swiper-button-next",
        prevEl: ".news-swiper-button-prev",
    },
    breakpoints: {
        768: {
            spaceBetween: 100,
            slidesPerView: 3,
            grid: {
                rows: 1
            }
        }
    }
});

var swiper = new Swiper(".participations-swiper", {
    slidesPerView: 1,
    modules: [Navigation, Grid],
    grid: {
        rows: 2
    },
    spaceBetween: 10,
    navigation: {
        nextEl: ".participations-swiper-button-next",
        prevEl: ".participations-swiper-button-prev",
    },
    breakpoints: {
        768: {
            slidesPerView: 3,
            grid: {
                rows: 1
            }
        },
        1024: {
            slidesPerView: 5,
            grid: {
                rows: 1
            }
        }
    }
});
